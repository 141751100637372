import Lenis from '@studio-freight/lenis'
import { loopback, map, clamp } from './math.js';
import { gsap } from "gsap";

export let doc = {
	// w: window.innerWidth,
	// h: window.innerHeight
	w: document.querySelector('.resize-helper').offsetWidth,
	h: document.querySelector('.resize-helper').offsetHeight
};

const getWindowSize = () => {
  doc = {
		// w: window.innerWidth,
		// h: window.innerHeight
    w: document.querySelector('.resize-helper').offsetWidth,
    h: document.querySelector('.resize-helper').offsetHeight
	}
  // r.style.setProperty('--100vh', `${doc.h}px`);
}

export const siteurl = document.body.dataset.siteurl
// console.log(siteurl)

let r = document.querySelector(':root');
r.style.setProperty('--100vh', `${doc.h}px`);

export let cursor = {
	x: 0.5,
	y: 0.5,
};

// export let scrl = {
	// scroll: 0,
	// velocity: 0,
  // direction: 'vertical',
  // progress: 0
// }

export let touchDevice = isTouchDevice()

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}


export let registeredLoops = []; //this is set to be empty on page

export const inViewport = (elPos) => {
	const progress = map(scrl.scroll + doc.h, elPos.y, elPos.y + elPos.h + doc.h);
	return {
		progress: progress,
		loopback: loopback(progress)
	}
}

export const getPosition = (element) => {
  return {
    x: element.getBoundingClientRect().left,
    y: element.getBoundingClientRect().top + document.documentElement.scrollTop,
    w: element.offsetWidth,
    h: element.offsetHeight,
  }
}

export const updatePositions = () => {
  // console.log(registeredLoops)
  registeredLoops.forEach(el => {
    el.position = getPosition(el.root);
  })
}
updatePositions()



let smoothScroll = true;
if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
  if(doc.w > 960) { //desktop only
    smoothScroll = false;
  }
}



export let scrollTop = 0;
handleScroll()
window.addEventListener('scroll', handleScroll);

function handleScroll() {
	scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
};


/*

//init Lenis scroll
const lenisOptions = {
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    direction: 'vertical', // vertical, horizontal
    gestureDirection: 'vertical', // vertical, horizontal, both
    smooth: smoothScroll,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
}

export let lenis;

const addLenis = () => {
  document.querySelector('.container').classList.remove('onload')
  lenis = new Lenis(lenisOptions)

  //save scroll value
  lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
    //console.log({ scroll, limit, velocity, direction, progress })
    scrl = {
      scroll: scroll,
      velocity: velocity,
      direction: direction,
      progress: progress
    }
  })
  lenis.start()
}

addLenis()

// global requestAnimationFrame, orginally for Lenis Scroll
function raf(time) {
  lenis.raf(time)

  registeredLoops.forEach(el => { //calls all functions that have been added to array
    el.loop(time)
  })
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)


export const stopLenis = () => {
  lenis.stop()
}
export const startLenis = () => {
  lenis.start()
}
export const lenisScrollTo = (el, duration, offset = 0) => {
  // console.log(el)
  lenis.scrollTo(el, {duration: duration, offset: offset, easing: (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2})  //https://easings.net/en#easeInOutCubic
}
*/

export function jumpTo(el, offset = 0, behavior = 'auto') { //behavior = 'smooth' for scroll animation
  if (el) {
    const elementPosition = el.getBoundingClientRect().top;
    const scrollToPosition = elementPosition + offset;
    window.scrollTo({
      top: scrollToPosition,
      behavior: behavior
    });
  }
}

//let gyroscopeActive = false;


export const initBackToTop = (wrapper) => {
  // console.log(wrapper)
  if(wrapper.querySelector('.back-to-top')) {
    wrapper.querySelector('.back-to-top').addEventListener('click', (e) => {
      window.scrollTo(0, 0);
      // lenis.scrollTo(wrapper.querySelector('.header', {duration: 1}))
    })
  }
}

document.addEventListener('mousemove', (event) => {
	//if(gyroscopeActive == false) {
		cursor = {
			x: map(event.clientX, 0, doc.w),
			y: map(event.clientY, 0, doc.h),
		}
		//console.log(cursor)
	//}
})


export const addGlobalEventListener = (type, selector, callback) => {
  document.addEventListener(type, e => {
    if (e.target.matches(selector)) callback(e)
  })
}

let lastTouchX = 0.5;
export let touchState = 0.5;

if(touchDevice == true) {
  document.addEventListener('touchstart', e => {
    lastTouchX = map(e.changedTouches[0].clientX, 0, doc.w)
  })

  document.addEventListener('touchmove', e => {
    touchState = clamp(touchState + (lastTouchX - map(e.changedTouches[0].clientX, 0, doc.w)))
    lastTouchX = map(e.changedTouches[0].clientX, 0, doc.w)
  })
}



//resize observer watching a helper (preventing ios url bar resize)
export let registeredResizeFunctions = []; //can be pushed to in individual component
let resizeCount = 0;
let doit;
const resizeObserver = new ResizeObserver((entries) => {
  // for (const entry of entries) {}
  if(resizeCount > 0) { //ignore observer being called on load
    getWindowSize()
    clearTimeout(doit); //cancle the timeout if new resize happend in the meantime
    doit = setTimeout(resizeUpdated, 500);
  }
  resizeCount++;
});
const resizeUpdated = () => {
  // console.log('resize')
  updatePositions()
  // console.log(registeredResizeFunctions)
  registeredResizeFunctions.forEach(el => {
    el.resize()
  })
}

resizeObserver.observe(document.querySelector(".resize-helper"));
