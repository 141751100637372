import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter
{
    constructor()
    {
        super() //calling the constructor of its parent (the EventEmitter)

        this.helper = document.querySelector(".resize-helper")
        this.width = this.helper.offsetWidth
        this.height = this.helper.offsetHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)


        //resize observer watching a helper (preventing ios url bar resize)
        let resizeCount = 0;
        let doit;
        const resizeObserver = new ResizeObserver((entries) => {
          // for (const entry of entries) {}
          if(resizeCount > 0) { //ignore observer being called on load
            // getWindowSize()
            clearTimeout(doit); //cancle the timeout if new resize happend in the meantime
            doit = setTimeout(resizeUpdated, 500);
          }
          resizeCount++;
        });
        const resizeUpdated = () => {
          // console.log('resize')
          this.getSizes()

          this.trigger('resize')
        }



        resizeObserver.observe(this.helper);


        // // Setup
        // this.width = window.innerWidth
        // this.height = window.innerHeight
        // this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // // Resize event
        // window.addEventListener('resize', () =>
        // {
        //     this.width = window.innerWidth
        //     this.height = window.innerHeight
        //     this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        //     this.trigger('resize')
        // })
    }

    getSizes() {
      this.width = this.helper.offsetWidth
      this.height = this.helper.offsetHeight
      this.pixelRatio = Math.min(window.devicePixelRatio, 2)
      // console.log(this.width, this.height, this.pixelRatio)
    }
}
