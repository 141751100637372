export default class projectHeader {
  init(root) {
    this.root = root
    this.closeBtn = this.root.querySelector('.button--close')
    this.setCloseParams()
  }
  setCloseParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let position;
    let names;
    if(urlParams.get('position')){
      position = urlParams.get('position')
      if(urlParams.get('names')){
        names = urlParams.get('names')
      }
      const params = `?position=${position}&names=${names}#project-list`
      this.closeBtn.href = this.closeBtn.href + params
    }
  }
}


