import * as THREE from 'three'
import Experience from '../Experience.js'
import { gsap , Expo, Power2 } from "gsap";
import { doc, cursor, updatePositions, scrollTop, siteurl, touchDevice, registeredLoops, initBackBtn, initBackToTop, startLenis, lenisScrollTo} from '../../utils/events.js';
import { map, clamp} from '../../utils/math.js';

export default class Logo
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.ditherScene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.sizes = this.experience.sizes
        this.debug = this.experience.debug
        this.ditherCanvas = document.querySelector('.webglDither')

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('logo')
        }

        // Resource
        this.resource = this.resources.items.logoModel
        this.matcap = this.resources.items.basicMatcap

        this.setModel()
        // this.setAnimation()
    }

    setModel() {
      this.modelGroup = new THREE.Group();

      this.model = this.resource.scene;
      this.resize()
      this.model.visible = false;
      this.modelGroup.add(this.model);
      this.scene.add(this.modelGroup);

      if (this.debug.active) {
        this.debugFolder.add(this.model, 'visible').name('Visible');
      }

      const matcapMaterial = new THREE.MeshMatcapMaterial({
        matcap: this.matcap, color: 0xffffff
      });
      matcapMaterial.encoding = THREE.sRGBEncoding;

      this.model.traverse((child) => {
        if (child.isMesh) {
          // Replace the existing material with the new material
          child.material = matcapMaterial;
        }
      });

      if(document.querySelector('.container').dataset.barbaNamespace == "default") {
        this.show()
      }
    }

    show() {
      this.resize()
      gsap.fromTo(this.modelGroup.position, {
        y: -this.sizes.height,
        x: 0,
      },{
        y: 0,
        x: 0,
        duration: 1,
        ease: Expo.easeOut,
        onStart: () => {
          this.model.visible = true;
          //move canvas to front
          this.ditherCanvas.style.zIndex = 100;
        }
      });
      this.ditherCanvas.style.mixBlendMode = "screen";
    }

    hide() {
      gsap.fromTo(this.modelGroup.position, {
        y: 0,
        x: 0,
      },{
        y: this.sizes.height,
        x: 0,
        duration: 0.35,
        ease: Power2.easeIn,
        onComplete: () => {
          // console.log('hide logo')
          this.model.visible = false;
          //move canvas back
          this.ditherCanvas.style.zIndex = -1;
        }
      });
      this.ditherCanvas.style.mixBlendMode = "normal";
    }

    resize() {
      // console.log('resize logo')
      // let logoSize = this.sizes.width / 9.5
      let logoSize = clamp(this.sizes.width / 12, 40, 160)
      this.model.scale.set(logoSize, logoSize, logoSize);
      this.model.position.set(0, logoSize / 4, -200);
    }

    update()
    {
      if(this.model && this.model.visible) { //rotate logo on cursor move
        this.model.rotation.x = map(cursor.y, 0, 1, 0.75, -0.25);
        this.model.rotation.y = map(cursor.x, 0, 1, -0.5, 0.5);
      }
    }
}
