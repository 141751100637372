import Experience from '../Experience.js'
import Environment from './Environment.js'
import Backdrop from './Backdrop.js'
import Logo from './Logo.js'
import Hand from './Hand.js'
import Can from './Can.js'
import Liquid from './Liquid.js'
// import Fox from './Fox.js'
// import House from './House.js'
// import Okonomie from './Okonomie.js'
// import Mobility from './Mobility.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            // this.floor = new Floor()
            // this.fox = new Fox()
            this.backdrop = new Backdrop()
            this.logo = new Logo()
            this.hand = new Hand()
            this.can = new Can()
            this.liquid = new Liquid()
            this.environment = new Environment()
        })
    }

    update()
    {
        if(this.backdrop)
            this.backdrop.update()
        if(this.logo)
            this.logo.update()
        if(this.hand)
            this.hand.update()
        if(this.can)
            this.can.update()
        if(this.liquid)
            this.liquid.update()
    }

    resize() {
      if(this.backdrop)
        this.backdrop.resize()
      if(this.logo)
        this.logo.resize()
      if(this.hand)
        this.hand.resize()
      if(this.liquid)
        this.liquid.resize()
      if(this.can)
        this.can.resize()
    }
}
