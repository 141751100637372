import * as THREE from 'three'
import Experience from '../Experience.js'
import { gsap , Expo, Power2 } from "gsap";
import { doc, cursor, updatePositions, scrollTop, siteurl, touchDevice, registeredLoops, initBackBtn, initBackToTop, startLenis, lenisScrollTo} from '../../utils/events.js';
import { map, clamp} from '../../utils/math.js';

export default class Hand
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.ditherScene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.sizes = this.experience.sizes
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('hand')
        }

        // Resource
        this.resource = this.resources.items.handModel
        this.matcap = this.resources.items.basicMatcap

        this.setModel()
    }

    setModel() {

      this.modelGroup = new THREE.Group();

      this.model = this.resource.scene;
      // this.resize()
      this.model.visible = false;
      this.modelGroup.add(this.model);
      this.scene.add(this.modelGroup);

      if (this.debug.active) {
        this.debugFolder.add(this.model, 'visible').name('Visible');
      }

      const matcapMaterial = new THREE.MeshMatcapMaterial({
        matcap: this.matcap, color: 0xffffff
      });
      matcapMaterial.encoding = THREE.sRGBEncoding;

      this.model.traverse((child) => {
        if (child.isMesh) {
          // Replace the existing material with the new material
          child.material = matcapMaterial;
        }
      });

      if(document.querySelector('.container').dataset.barbaNamespace == "contact") {
        this.show()
      }
    }

    show() {
      this.resize()
      gsap.fromTo(this.modelGroup.position, {
        y: -this.sizes.height,
        x: 0,
      },{
        y: 0,
        x: 0,
        duration: 1,
        ease: Expo.easeOut,
        onStart: () => {
          this.model.visible = true;
        }
      });
    }

    hide() {
      gsap.fromTo(this.modelGroup.position, {
        y: 0,
        x: 0,
      },{
        y: -this.sizes.height,
        x: -this.sizes.height,
        duration: 0.35,
        ease: Power2.easeIn,
        onComplete: () => {
          // console.log('hide hand')
          this.model.visible = false;
        }
      });
    }

    resize() {
      // console.log('resize hand')

      //the following settings would be perfect, but they are needed before the content is rendered
      // var headline = document.querySelector('.intro-headline').getBoundingClientRect();
      // console.log(headline.bottom)
      // this.posYLarge = (this.sizes.height * 0.5) - (headline.top + scrollTop + (headline.height * 0.5))
      // this.posYMedium = (this.sizes.height * 0.5) - (headline.top + scrollTop + (headline.height * 0.75))
      // this.posYSmall = (this.sizes.height * 0.5) - (headline.bottom + scrollTop + 180)

      //hardcoded y positions
      this.posYSmall = (this.sizes.height * 0.5) - 350
      this.posYMedium = (this.sizes.height * 0.5) - 400
      this.posYLarge = (this.sizes.height * 0.5) - 280
      this.posYLargeX = (this.sizes.height * 0.5) - 350
      this.posYWide = 0

      this.offsetY = this.posYWide;
      let handSize = clamp(this.sizes.width / 20, 30, 80)
      this.model.scale.set(handSize, handSize, handSize);
      this.model.position.set(this.sizes.width * -0.25, 0, -handSize * 2); //right, center
      if(this.sizes.width < 500) {
        this.offsetY = this.posYSmall
        this.model.position.x = 0;
      } else if(this.sizes.width < 720) {
        this.offsetY = this.posYMedium
        this.model.position.x = 0;
      } else if(this.sizes.width < 1000) {
        this.offsetY = this.posYLarge
      } else if(this.sizes.width < 1200) {
        this.offsetY = this.posYLargeX
      }
    }

    update()
    {
      if(this.model && this.model.visible) { //rotate hand on cursor move
        this.model.position.y = (scrollTop * 0.6) + this.offsetY //move hand on scroll
        this.model.rotation.x = map(cursor.y, 0, 1, 0.5, -0.25) + scrollTop * 0.0005;
        this.model.rotation.y = map(cursor.x, 0, 1, -0.5, 0.5);
      }
    }
}
