const siteurl = document.body.dataset.siteurl

export default [
  {
    name: `logoModel`,
    type: `gltfModel`,
    path: `${siteurl}/assets/models/PivoLogo/pivo-logo-spline-9-wo-lights.gltf`
  },
  {
    name: `basicMatcap`,
    type: `texture`,
    path: `${siteurl}/assets/textures/matcaps/basic-matcap.png`
  },
  {
    name: `liquidSprite`,
    type: `texture`,
    path: `${siteurl}/assets/textures/liquid/liquid-sprite-sheet-9-9-tiny.jpg`
  },
  {
    name: `handModel`,
    type: `gltfModel`,
    path: `${siteurl}/assets/models/PhoneHand/call-me-hand.gltf`
  },
  {
    name: `canModel`,
    type: `gltfModel`,
    path: `${siteurl}/assets/models/PivoCan/pivo-can-wo-alc-lowpoly2.gltf`
  }
]
