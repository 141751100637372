export const DitherShader = {
  uniforms:
  {
      tDiffuse: { value: null },
      uClamp: { value: null },
      uTransparency: {value: 0.},
      uPixelColor: { value: {x: 1., y: 1., z: 1.} },
      uBGColor: { value: {x: 0., y: 0., z: 0.} },
  },
  vertexShader: `
      varying vec2 vUv;

      void main()
      {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

          vUv = uv;
      }
  `,
  fragmentShader: `
      //Dither: http://alex-charlton.com/posts/Dithering_on_the_GPU/
      // https://surma.dev/things/ditherpunk/

      uniform sampler2D tDiffuse;
      uniform vec2 uClamp;
      uniform float uTransparency;
      uniform vec3 uPixelColor;
      uniform vec3 uBGColor;
      varying vec2 vUv;

      float map(float value, float min1, float max1, float min2, float max2) {
          return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
      }

      const int indexMatrix4x4[16] = int[](0,  8,  2,  10,
                                          12, 4,  14, 6,
                                          3,  11, 1,  9,
                                          15, 7,  13, 5);


      float indexValue() {
          float x = mod(gl_FragCoord.x, 4.);
          float y = mod(gl_FragCoord.y, 4.);
          int intX = int(x);
          int intY = int(y);

          // Type conversions can be done using constructors..."  https://stackoverflow.com/questions/33270823/how-to-cast-int-to-float-in-glsl-webgl
          float i_float = float(indexMatrix4x4[(intX + intY * 4)]);
          return i_float / 16.0;
      }

      float dither(float color) {
          float closestColor = (color < 0.5) ? 0. : 1.;
          float secondClosestColor = 1. - closestColor;
          float d = indexValue();
          float distance = abs(closestColor - color);
          return (distance < d) ? closestColor : secondClosestColor;
      }

      void main () {
          vec4 color = texture2D(tDiffuse, vUv);
          float brightness = (color.x + color.y + color.z) / 3.; //greyscale
          // brightness = clamp(map(brightness, 0., 1., -0.1, 1.1), 0., 1.); //contrast

          float finalBrightness;
          if(brightness > uClamp.x && brightness < 1. - uClamp.y) {
              finalBrightness = dither(brightness);
          } else {
              finalBrightness = round(brightness);
          };

          // gl_FragColor = vec4(uPixelColor, finalBrightness); //producing a transparency bug on ios
          if(finalBrightness == 1.) {
            gl_FragColor = vec4(uPixelColor, 1.);
          } else {
            gl_FragColor = vec4(uBGColor, uTransparency);
          }
      }
  `
}
