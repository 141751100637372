import { spanFerkel } from './../../js/utils/math.js';
import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, touchDevice, siteurl, lenisScrollTo, jumpTo, addGlobalEventListener} from './../../js/utils/events.js';
import { gsap } from "gsap";

export default class ProjectFilter {
  init(root) {
    this.root = root;
    this.projectListEl = document.querySelector('.project-list')
    this.projectArtistProfileEl = document.querySelector('.project-filter-artistprofile')
    this.servicesEl = this.root.querySelector('.project-filter-services .options')
    this.namesEl = this.root.querySelector('.project-filter-names .options')
    this.resultEl = this.root.querySelector('.project-filter-result')
    this.resetServices = this.root.querySelector('.project-filter-services .reset')
    this.resetNames = this.root.querySelector('.project-filter-names .reset')
    this.namesInfo = this.root.querySelector('.project-filter-names .info')

    this.resetNames.addEventListener('click', e => {
      this.namesEl.querySelectorAll('button').forEach(btn => {
        if(!btn.classList.contains('active')) {
          btn.classList.add('active')
          this.filter()
        }
      })
    })
    this.resetServices.addEventListener('click', e => {
      this.showFeatured()
    })

    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);

    this.workJSON;
    fetch(`${siteurl}/work.json`)
    .then(results => results.json())
    .then( data => {
      //this.filter(data);
      this.workJSON = data
      this.buildFilter();
      if(this.urlParams.get('position') || this.urlParams.get('singlename')){
        this.filterByURLParams()
      } else {
        this.showFeatured();
      }
    });
  }
  buildFilter() {
    const filteredArray = this.workJSON[0].portfolio.reduce((acc, cur) => {
      cur.members.forEach(member => {
        const index = acc.findIndex(item => item.position === member.position);
        if (index === -1) {
          acc.push({position: member.position, members: [member.name]});
        } else {
          acc[index].members.push(member.name);
        }
      });
      return acc;
    }, []);

    //create HTML filter options
    filteredArray.forEach(el => {
      let value = el.position
      const filteredPosition = filteredArray.filter(item => item.position === value).map(item => {
        return {
          position: item.position,
          members: Array.from(new Set(item.members))
        }
      });
      let btn = document.createElement("button");
      btn.setAttribute('data-filter', el.position);
      btn.setAttribute('data-names', filteredPosition[0].members);
      btn.innerHTML = value;
      btn.addEventListener('click', (e) => {
        this.showNameOptions(filteredPosition[0].members)
        this.applyServiceFilter(e)
      })
      this.servicesEl.appendChild(btn);
    })
  }
  applyServiceFilter(e) {
    if(e.target.classList.contains('active')) {
      e.target.classList.remove('active')
      this.namesEl.innerHTML = ""
    } else {
      this.servicesEl.querySelectorAll('button').forEach(el => {
        el.classList.remove('active');
      })
      e.target.classList.add('active')
    }
    this.filter()
  }
  showNameOptions(members) {
    this.namesEl.innerHTML = ""
    this.namesInfo.classList.remove('hidden');
    members.forEach(member => {
      let btn = document.createElement("button");
      btn.setAttribute('data-filter', member);
      btn.innerHTML = member;
      // btn.classList.add('active')
      btn.addEventListener('click', (e) => {
        if(btn.classList.contains('active')) {
          btn.classList.remove('active')
        } else {
          this.namesEl.querySelectorAll('button').forEach(el => {
            el.classList.remove('active');
          })
          btn.classList.add('active')
        }
        this.filter()
      })
      this.namesEl.appendChild(btn);
    })
  }
  filter() {
    //look up filter inputs
    let activeNames = [];
    let service;
    if(this.servicesEl.querySelector('.active')) {
      service = this.servicesEl.querySelector('.active').innerHTML
    }
    this.namesEl.querySelectorAll('.active').forEach(name => {
      activeNames.push(name.innerHTML)
    })
    if(activeNames.length == 0) { //no artist selected -> show all artists in category
      this.namesEl.querySelectorAll('button').forEach(name => {
        activeNames.push(name.innerHTML)
      })
    }
    const filterInput  = {
      position: service,
      names: activeNames
    }
    this.filterInput = filterInput;

    //filter the original array with the filter inputs
    const filteredArray = this.workJSON[0].portfolio.filter(function(object) {
      return object.members.filter(member => member.position === filterInput.position && filterInput.names.includes(member.name)).length;
    });
    this.renderThumbnails(filteredArray)
    if(filterInput.names.length == 0) {
      if(filterInput.position == undefined) {
        this.showFeatured()
      } else {
        this.resultEl.innerHTML = `0 Projects. Please select an artist`
      }
    } else {
      this.resultEl.innerHTML = `${filteredArray.length} Projects by ${filterInput.names.join(', ')}`
    }
  }
  showFeatured() {
    this.renderThumbnails(this.workJSON[0].featured)
    this.resultEl.innerHTML = `Featured Work`
    this.namesEl.innerHTML = ""
    this.servicesEl.querySelectorAll('button').forEach(btn => {
      btn.classList.remove('active')
    })
    this.namesInfo.classList.add('hidden');
  }
  filterByURLParams() {
    let singlename = this.urlParams.get('singlename')
    if(singlename){
      this.filterSingleName(singlename)
    } else {
      let position = this.urlParams.get('position')
      const currentServiceEl = this.servicesEl.querySelector(`[data-filter='${position}']`);
      currentServiceEl.classList.add('active');
      let names = this.urlParams.get('names').split(',')
      this.showNameOptions(currentServiceEl.getAttribute('data-names').split(','))
      if(names.length < 2) { //if more than one it will show all
        this.namesEl.querySelector(`[data-filter='${names[0]}']`).classList.add('active');
      }
      this.filter()
      window.setTimeout(() => {
        jumpTo(this.root, -16, 'smooth')
      }, 200);
    }
  }
  filterSingleName(name) {
    //show some overlay
    this.root.querySelector('.project-filter-singlename .singlename').innerHTML = name;
    this.root.classList.add('singlename-active')

    const filteredArray = this.workJSON[0].portfolio.filter(function(object) {
      return object.members.some(member => member.name === name);
    });
    this.renderThumbnails(filteredArray)

    //add close button -> featured
    this.root.querySelector('.project-filter-singlename .singlename-hide').addEventListener('click', e => {
      this.root.classList.remove('singlename-active')
      this.showFeatured();
    })

    window.setTimeout(() => {
      jumpTo(this.root, -16, 'smooth')
    }, 200);
  }
  mouseMove(e) {
    const bounds = e.target.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2
    }
    const distance = Math.sqrt(center.x**2 + center.y**2);

    e.target.parentElement.style.zIndex = 10;
    e.target.style.transform = `
      scale3d(1.17, 1.17, 1.17)
      translate3d(0 ,0 , 40px)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance)* 1.75}deg
      )
    `;
    // e.target.querySelector('.glow').backgroundImage = `
    //   radial-gradient(
    //     circle at
    //     ${center.x * 2 + bounds.width/2}px
    //     ${center.y * 2 + bounds.height/2}px,
    //     #ffffff55,
    //     #0000000f
    //   )
    // `;
  }
  mouseLeave(e) {
    e.target.style.transform = '';
    e.target.parentElement.style.zIndex = '';
    // e.target.style.zIndex = '';
    // e.target.style.background = '';
    // e.target.transition = 'transform 200ms';
  }
  renderThumbnails(filteredArray) {
    let projectThumbnails = "";

    this.projectArtistProfileEl.innerHTML = ""

    let params = "";
    if(this.filterInput) {
      params = `?position=${this.filterInput.position}&names=${this.filterInput.names}`

      if(this.filterInput.names.length === 1) {
        let name = this.filterInput.names[0];
        let specificArtist = this.workJSON[0].artists.filter(artist => artist.name === name)[0];
        console.log(specificArtist);

        if(specificArtist.image) {
          this.projectArtistProfileEl.innerHTML = `
            <div class="project-filter-artistprofile-wrapper">
              <img class="project-filter-artistprofile-image" src="${specificArtist.image}" />
              <div class="project-filter-artistprofile-description">
                <p class="label">About</p>
                <h2>${specificArtist.name}</h2>
                <div>${specificArtist.about}</div>
              </div>
            </div>
          `
        }
      }
    }


    filteredArray.forEach(thumb => {
      let url = thumb.url;
      let title = thumb.title;
      let mainimage = thumb.mainimage;
      let titleSpan = spanFerkel(thumb.title)
      const template = `
        <div class="project__item-wrapper">
          <a class='project__item use-project-transition'
              href='${url}${params}'>
              <div class="imagewrapper">
                <img alt='Project: ${title}' loading='lazy' src='${mainimage}'/>
              </div>
            <h3 class='project__title'>${titleSpan}</h3>
          </a>
        </div>
      `
      projectThumbnails += template;
    })

    //remove events from old items
    const oldItems = this.projectListEl.querySelectorAll('.project__item')
    if(touchDevice === false && doc.w > 720) {
      oldItems.forEach(el => {
        el.removeEventListener('mousemove', this.mouseMove);
        el.removeEventListener('mouseleave', this.mouseLeave);
      })
    }

    gsap.to(oldItems, {
      y: '10%',
      opacity: 0,
      duration: 0.2,
      stagger: {
        each: 0.1,
        grid: "auto"
      },
      onComplete: () => {
        this.projectListEl.innerHTML = projectThumbnails;
        //add events to new items
        const newItems = this.projectListEl.querySelectorAll('.project__item')
        newItems.forEach(el => {
          if(touchDevice === false && doc.w > 720) {
            el.addEventListener('mousemove', this.mouseMove);
            el.addEventListener('mouseleave', this.mouseLeave);
          }
        })
        window.setTimeout(() => {
          gsap.fromTo(this.projectListEl.querySelectorAll('.project__item'), {
            y: '10%',
            opacity: 0,
          }, {
            y: '0',
            opacity: 1,
            duration: 0.2,
            stagger: {
              each: 0.1,
              grid: "auto"
            },
            onComplete: function () {
              gsap.set(this.targets(), { clearProps: "transform" });
            }
          })
        }, 100);
      }
    })
  }
}

