export default class Contact {
    init(root) {
        this.root = root
        this.render()
    }

    render() {

      //getting
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const contact = urlParams.get('contact')

      if(contact == "success") {
        document.querySelector('.contact-success').style.display = "block";
        this.root.style.display = "none";
        //document.querySelector('.contact-headline').style.display = "none";
      }
    }
}
