import * as THREE from 'three'
import Experience from '../Experience.js'
import planeFragmentShader from './../Utils/shaders/fragment-cursor.glsl'
import { doc, cursor, updatePositions, scrl, siteurl, touchDevice, registeredLoops, initBackBtn, initBackToTop, startLenis, lenisScrollTo} from '../../utils/events.js';
import { map, clamp} from '../../utils/math.js';

export default class Backdrop
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.ditherScene
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        this.longSide = Math.max(this.sizes.width,this.sizes.height);
        this.aspectRatio = this.sizes.width / this.sizes.height

        this.setGeometry()
    }

    setGeometry()
    {
        this.planeGeometry = new THREE.PlaneGeometry(1,1);
        const planeVertexShader = `
            uniform vec2 mouse;
            uniform float time;
            uniform vec2 uClickPos;
            uniform float uProgress;
            varying vec2 vUv;
            void main() {
              vUv = uv;
              vec3 pos = position;
              // pos.x += mouse.x * 0.2;
              // pos.y += mouse.y * 0.2;
              gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );
            }
          `;
        this.planeMaterial = new THREE.ShaderMaterial( {
          uniforms: {
            mouse: { value: new THREE.Vector2(0.5,0.5) },
            uClickPos: { value: new THREE.Vector2(0.5,0.5) },
            uProgress: { value: 0. },
            time: { value: 0. }
          },
          vertexShader: planeVertexShader,
          fragmentShader: planeFragmentShader
        });

        // this.planeMaterial = new THREE.MeshBasicMaterial({ color: 0x00ffff});

        this.planeMesh = new THREE.Mesh( this.planeGeometry, this.planeMaterial);
        this.planeMesh.rotation.set(0,Math.PI,0);
        this.planeMesh.visible = true;
        this.setPlaneSize()
        this.scene.add(this.planeMesh)

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('backdrop (cursor & waves)')
            this.debugFolder.add(this.planeMesh, 'visible').name('Visible');
            this.debugFolder.add(this.planeMaterial.uniforms.uProgress, 'value' ).min(0).max(1).step(0.001).name('Wave Progress');
        }

    }

    setPlaneSize() {
      //match size with screen, but keep the plane a square
      // this.longSide = {value: Math.max(this.sizes.width,this.sizes.height), xMultiplyer: 0, yMultiplyer: 0};
      this.planeMesh.scale.set(this.longSide, this.longSide, 1)
      //push to front and cover everything:
      this.planeMesh.position.set(0,0,0);
    }

    setClickPos(cursor) {
      const planeCursor = this.positionCursorOnPlane(cursor)
      this.planeMaterial.uniforms.uClickPos.value = new THREE.Vector2(planeCursor.x,planeCursor.y)
    }

    positionCursorOnPlane(cursor) {
      let x, y;

      if (this.aspectRatio > 1) { // canvas is landscape
          x = cursor.x;
          y = 1 - (cursor.y * (1 / this.aspectRatio) + (1 - 1 / this.aspectRatio) / 2);
      } else { // canvas is portrait
          x = cursor.x * this.aspectRatio + (1 - this.aspectRatio) / 2;
          y = 1 - cursor.y;
      }

      return { x, y };
    }

    resize() {
      //match size with screen
      // console.log('resize backdrop')
      this.longSide = Math.max(this.sizes.width,this.sizes.height);
      this.aspectRatio = this.sizes.width / this.sizes.height
      this.setPlaneSize()
    }

    update() {
      const planeCursor = this.positionCursorOnPlane(cursor);
      // console.log(planeCursor)
      // console.log(this.planeMaterial.uniforms.uProgress)
      if(touchDevice === true) {
        this.planeMaterial.uniforms.mouse.value.x = -1.
        this.planeMaterial.uniforms.mouse.value.y = -1.
      } else {
        this.planeMaterial.uniforms.mouse.value.x = planeCursor.x; //map(cursor.x, 0, 1, range.rangeX.left, range.rangeX.right)
        this.planeMaterial.uniforms.mouse.value.y = planeCursor.y; //map(cursor.y, 0, 1, range.rangeY.top, range.rangeY.bottom)
      }
    }
}
