import * as THREE from 'three'
import gsap from "gsap";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import EventEmitter from './Utils/EventEmitter.js'
import Experience from './Experience.js'

export default class Camera extends EventEmitter
{
    constructor()
    {
        super() //calling the constructor of its parent (the EventEmitter)

        this.experience = new Experience() //Experience is a singelton -> not initialized twice, just a reference
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.currentScene = null

        this.minpolar = 1;
        this.maxpolar = Math.PI / 2.1;

        this.twoPi = Math.PI * 2

        this.setInstance()
        this.setControls()
        this.setupZoom()
    }

    setInstance()
    {
        // this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)
        // this.instance.position.set(0, 0, -4)

        // this.instance = new THREE.OrthographicCamera( this.sizes.width / - 2, this.sizes.width / 2, this.sizes.height / 2, this.sizes.height / - 2, -100, 100 );
        // this.instance.position.set(0, 0, 0);

        this.instance = new THREE.PerspectiveCamera(70, this.sizes.width / this.sizes.height, 10, 2000)
        this.instance.position.x = 0
        this.instance.position.y = 0
        this.instance.position.z = -1200
        this.instance.fov = 2*Math.atan((this.sizes.height/2)/1200) * (180/Math.PI) // scale to align with pixels
        this.instance.updateProjectionMatrix();

        this.scene.add(this.instance)
    }

    setControls()
    {
      //animateable controls alternative: https://www.npmjs.com/package/camera-controls
      this.controls = new OrbitControls(this.instance, this.canvas)
      this.controls.enableDamping = true
      this.controls.rotateSpeed = 0.8
        // this.controls.autoRotate = true
        // this.controls.autoRotateSpeed = -0.5
      this.controls.enablePan = false
      this.controls.enableZoom = true
      // this.controls.minPolarAngle = this.minpolar; // radians
      // this.controls.maxPolarAngle = this.maxpolar; // radians
    }

    autoRotate(state, speed) {
      this.controls.autoRotate = state
      this.controls.autoRotateSpeed = speed
    }


    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    setupZoom() {

    }


    update()
    {
        this.controls.update()
    }
}
