import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, registeredResizeFunctions, touchDevice, siteurl, addGlobalEventListener} from './../../js/utils/events.js';
import { clamp } from './../../js/utils/math.js';

export default class ImageStack {
  init(root) {
    this.root = root;
    this.items = this.root.querySelectorAll('.imagestack-item');

    registeredResizeFunctions.push({ //add this.loop to global requestAnimationFrame
      root: this.root,
      component: this,
      resize: this.resize
    })

    this.items.forEach((el, i) => {
      this.positionItem(el, i)
    })
  }

  positionItem(el, i) {
    let itemWidth = el.offsetWidth
    let itemHeight = el.offsetHeight

    const randomValueA = (Math.random() * 2) - 1;
    const randomValueB = (Math.random() * 1);
    const randomValueC = (Math.random() * 2) - 1;


    let offsetX = 0;
    let offsetY = 0;
    if(i != 0) { //no offset for first item
      offsetX = clamp(doc.w - 64 - itemWidth, 0, doc.w) / 2 * randomValueA;
      if(doc.w > doc.h) {
        offsetY = clamp(doc.h - 64 - itemHeight, 0, doc.w) / 4 * randomValueB;
      }
    }

    el.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0) rotate(${randomValueC * 6}deg)`
    if(doc.w > doc.h) {
      el.style.top = `${(doc.h - itemHeight) / 2}px`;
    } else {
      el.style.top = `${32}px`;
    }
  }

  resize() {
    this.component.items.forEach((el, i) => {
      this.component.positionItem(el, i)
    })
  }
}

