  import * as THREE from 'three'
  import Experience from '../Experience.js'
  import { doc, cursor, updatePositions, scrollTop, siteurl, touchDevice, registeredLoops, initBackBtn, initBackToTop, startLenis, lenisScrollTo} from '../../utils/events.js';
  import { map, clamp, lerp} from '../../utils/math.js';

  export default class Liquid
  {
      constructor()
      {
        this.experience = new Experience()
        this.scene = this.experience.ditherScene
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.video = document.querySelector('.liquid-video')
        this.debug = this.experience.debug
        this.geometryIsSet = false
        this.videoReady = false
        this.videoProgress = 0;

        this.currentTile = 0;
        this.tilesHoriz = 9
        this.tilesVert = 9
        this.tilesAmount = this.tilesHoriz * this.tilesVert

        this.spriteTexture = this.resources.items.liquidSprite


        if(document.querySelector('.container').dataset.barbaNamespace == "culture") {
          this.initGeometry()
        }
      }

      initGeometry()
      {
        if(this.geometryIsSet === false) {
            this.videoReady = true;
            // this.spriteTexture.repeat.set(0.25, 0.25)
            const offsetSample = 0.001; //to hide a blinking pixel row
            this.spriteTexture.repeat.set(1 / this.tilesHoriz - offsetSample, 1 / this.tilesVert - offsetSample)
            this.spriteTexture.magFilter = THREE.NearestFilter; //sharp edges

            this.spriteMaterial = new THREE.SpriteMaterial({ map: this.spriteTexture });
            this.planeMesh = new THREE.Sprite(this.spriteMaterial);

            this.planeMesh.renderOrder = 1;
            this.planeMesh.visible = true;
            this.initLiquid();
            this.scene.add(this.planeMesh);

            this.geometryIsSet = true;

            this.coverGeometry = new THREE.PlaneGeometry(this.sizes.width,400);
            this.coverMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff});
            this.coverMesh = new THREE.Mesh(this.coverGeometry, this.coverMaterial);
            this.coverMesh.rotation.set(0, Math.PI, 0);
            this.coverMesh.position.z = -10;
            this.scene.add(this.coverMesh);

            // Debug
            if (this.debug.active) {
                this.debugFolder = this.debug.ui.addFolder('liquid plane (pouring beer)');
                this.debugFolder.add(this.planeMesh, 'visible').name('Visible');
            }
        } else {
          this.initLiquid()
        }
      }

      initLiquid() {
        this.planeMesh.scale.set(this.sizes.width, this.sizes.width, 1)
        this.planeMesh.visible = true;
        this.canPourArea = document.querySelector('.can-pour')
        this.canPourAreaTop = this.canPourArea.offsetTop

        this.offsetAnimation = ((this.sizes.height - this.sizes.width) / 2) + (this.sizes.width / 5)//to start when liquid hits bottom

        this.animationStart = this.canPourAreaTop - this.sizes.height - this.offsetAnimation
        this.animationEnd = this.canPourAreaTop + this.sizes.width - this.offsetAnimation
        //push infront of Backdrop Plane, and align to top:
        this.offsetY = (this.sizes.height - this.sizes.width) * 0.5 - this.canPourAreaTop
        this.planeMesh.position.set(0,this.offsetY, -1);
      }

      hide() {
        this.planeMesh.visible = false;
        this.geometryIsSet = false
        this.videoReady = false
        this.videoProgress = 0;


        if (this.planeMesh) {
          // console.log('destroy Liquid mesh')
          this.planeMesh.material.dispose();
          if (this.planeMesh.material.map) {
            this.planeMesh.material.map.dispose();
          }
          this.planeMesh.geometry.dispose(); // Dispose geometry
          this.scene.remove(this.planeMesh);
          this.planeMesh = null;
        }

        if (this.coverMesh) {
          // console.log('destroy coverMesh mesh')
          this.coverMesh.material.dispose();
          if (this.coverMesh.material.map) {
            this.coverMesh.material.map.dispose();
          }
          this.coverMesh.geometry.dispose(); // Dispose geometry
          this.scene.remove(this.coverMesh);
          this.coverMesh = null;
        }
      }

      resize() {
        if(this.geometryIsSet === true && this.planeMesh.visible === true) {
          this.initLiquid()
        }
      }

      update() {
        if(this.geometryIsSet && this.planeMesh.visible === true) {
          this.planeMesh.position.y = scrollTop + this.offsetY //move hand on scroll
          this.coverMesh.position.y = scrollTop + this.offsetY - (this.sizes.width * 0.5) -180 //move cover on scroll

          let progress = clamp(map(scrollTop, this.animationStart, this.animationEnd, 0, 1))

          let liquidProgress = clamp(map(progress, 0.5, 0.95, 0, 1))

          const currentTileIndex = Math.floor(liquidProgress * this.tilesAmount);

          const col = (currentTileIndex % this.tilesVert) / this.tilesHoriz;
          const row =  clamp((this.tilesHoriz - 1 - Math.floor(currentTileIndex / this.tilesHoriz)) / this.tilesVert);
          // console.log(col,row, currentTileIndex)

          this.spriteTexture.offset.x = col;
          this.spriteTexture.offset.y = row;

          // this.spriteTexture.offset.x = progress;
          // this.spriteTexture.offset.y = progress;
          // this.spriteTexture.offset.y = this.offsetY;

          // if(this.currentTile != progress) {
          //   console.log(this.currentTile)
          //   this.currentTile = progress
          //   // this.setSpriteTile()
          // }
        }
      }
  }
