import '../scss/app.scss';

// Your JS Code goes here
// import Hero from '../components/hero/hero.js'
// import WebGL from '../components/webgl/webgl.js'
import barba from '@barba/core';
import { gsap } from "gsap";

//math & events
import { map, clamp, loopback, spanFerkel } from './utils/math.js';
import { doc, cursor, updatePositions, scrl, siteurl, touchDevice, registeredLoops, initBackBtn, initBackToTop, startLenis, lenisScrollTo} from './utils/events.js';


const black = '#000';
const offblack = '#1A1A1A';
const white = '#fff';
const offwhite = '#F7F6F6';
const orange = '#FF5C00';
const offorange = '#ff5100';

const pageColors = {
  default: {
    localBackground: black,
    localOffColor: white,
    localForeground: white
  },
  work: {
    localBackground: black,
    localOffColor: offblack,
    localForeground: white
  },
  culture: {
    localBackground: orange,
    localOffColor: white,
    localForeground: white
  },
  contact: {
    localBackground: offorange,
    localOffColor: white,
    localForeground: white
  },
  studios: {
    localBackground: offwhite,
    localOffColor: white,
    localForeground: black
  },
  project: {
    localBackground: offblack,
    localOffColor: black,
    localForeground: white
  },
  legal: {
    localBackground: offwhite,
    localOffColor: white,
    localForeground: black
  },
}



// Components
import Contact from '../components/contact/contact.js'
import ProjectFilter from '../components/project-filter/project-filter.js'
// import WebGlDither from '../components/webgl-dither/webgl-dither.js'
// import WebGlCan from '../components/webgl-can/webgl-can.js'
import VimeoConsent from '../components/vimeo-consent/vimeo-consent.js'
import Stage from '../components/stage/stage.js'
import Intro from '../components/intro/intro.js'
import ImageStack from '../components/imagestack/imagestack.js'
import ProjectHeader from '../components/project-header/project-header.js'

//init Components on load
initalizeComponents(document);

import Experience from './Experience/Experience.js'
const experience = new Experience(document.querySelector('canvas.webgl'))
const backdrop = experience.world.backdrop


const initalContainer = document.body.querySelector('.container')

barba.init({
	preventRunning: true,
	debug: false,
	// logLevel: 'debug',
	transitions: [
    { //pixel wave transition
      name: 'pixel-wave-transition',
      leave(data) {
        const backdrop = experience.world.backdrop
        const ditherRenderer = experience.ditherRenderer
        backdrop.setClickPos(cursor);
        data.current.container.style.zIndex = -1;

        setTimeout(() => {
          // console.log('set colors for transition')
          let background;
          if(pageColors[data.current.namespace]) {
            background = pageColors[data.current.namespace].localBackground;//current BG Color
          } else {
            background = '#000'
          }
          let front;
          if(pageColors[data.next.namespace]) {
            front = pageColors[data.next.namespace].localBackground;//next BG Color
          } else {
            front = '#FF5C00'
          }
          ditherRenderer.setColors(background, front);
        }, 100)

        return gsap.timeline().to(data.current.container, {
          opacity: 0,
          duration: 0.5,
          y: 200,
          delay: 0,
          stagger: 0.1,
          onStart:  () => leaving(data),
        }).fromTo(backdrop.planeMaterial.uniforms.uProgress,{
            value: 0,
          }, {
            value: 1,
            duration: 1.2,
            onComplete: () => setColors(data.next.namespace)
        }, 0);
      },
      enter(data) {
        return gsap.from(data.next.container, { //.querySelectorAll('main header, main div, main form, main section')
          opacity: 0,
          duration: 1,
          y: 200,
          // stagger: 0.1,
          // delay: 0.2,
          onStart:  () => entering(data),
          onComplete: () => finished(data)
        });
      }
    }
	]
});

function leaving(data) {

  // if (data.current.namespace == 'culture') {
  //   can.pageLeave()
  // }

  if(data.current.namespace == "contact") {
    // console.log('leaving contact page')
    experience.world.hand.hide()
  }
  if(data.current.namespace == "default") {
    // console.log('leaving default page')
    experience.world.logo.hide()
  }
  if(data.current.namespace == "culture") {
    // console.log('leaving culture page')
    experience.world.liquid.hide()
    experience.world.can.hide()
  }
}

function entering(data) {
  window.scrollTo(0, 0);
  initBackToTop(data.next.container)
  registeredLoops.length = 0; //reset loops

  // console.log('entering')
  // if(data.next.namespace == "default") {
  //   //add css filter for transparent logo (workaround)
  //   document.querySelector('.webglDither').style.mixBlendMode = "screen";
  // } else {
  //   //remove css filter
  //   document.querySelector('.webglDither').style.mixBlendMode = "normal";
  // }

  const clock = data.next.container.querySelector('.clock')
  if(clock) {
    setInterval(() => {
      clock.innerHTML = new Date().toLocaleTimeString();
    }, 1000);
  }

  if(data.next.container.querySelector('.intro-headline')) {
    const introHeadline = data.next.container.querySelector('.intro-headline')

    let headlineSpan = spanFerkel(introHeadline.innerHTML)
    introHeadline.innerHTML = headlineSpan

    const words = introHeadline.querySelectorAll('mark');

    words.forEach(word => {
      const letters = word.querySelectorAll('span');
      gsap.to(letters,{
        y: 0,
        duration: 0.2,
        stagger: 0.05
      })
    })

  }

  if(data.next.namespace == "contact") {
    //  console.log('arriving at contact page')
    experience.world.hand.show()
  }

  if(data.next.namespace == "default") {
    //  console.log('arriving at default page')
    experience.world.logo.show()
  }

  if(data.next.namespace == "culture") {
    // console.log('arriving at culture page')
    experience.world.liquid.initGeometry()
    experience.world.can.show()
  }

  //init Components on page transition
  initalizeComponents(data.next.container);
}

function setColors(namespace) {
  // console.log('set colors AFTER transition')
  document.body.style.backgroundColor = pageColors[namespace].localBackground
  const background = pageColors[namespace].localBackground;
  const front = pageColors[namespace].localOffColor;
  const ditherRenderer = experience.ditherRenderer
  ditherRenderer.setColors(background, front);
  const backdrop = experience.world.backdrop
  if(backdrop) {
    backdrop.planeMaterial.uniforms.uProgress.value = 0;
  }
}


function initalizeComponents(container) {

  if(container.querySelector(".project-filter")) {
    let projectFilter = new ProjectFilter();
    let ProjectFilterEl = container.querySelector(".project-filter");
    projectFilter.init(ProjectFilterEl)
  }

  if(container.querySelector(".contact")) {
    let contactEl = container.querySelector(".contact");
    let contact = new Contact();
    contact.init(contactEl)
  }

  if(container.querySelector(".vimeo-consent")) {
    let vimeoConsentEl = container.querySelector(".vimeo-consent");
    let vimeoConsent = new VimeoConsent();
    vimeoConsent.init(vimeoConsentEl)
  }

  if(container.querySelector(".stage")) {
    let stageEl = container.querySelector(".stage");
    let stage = new Stage();
    stage.init(stageEl)
  }

  if(container.querySelector(".imagestack")) {
    let imagestackEls = container.querySelectorAll(".imagestack");
    imagestackEls.forEach(imagestackEl => {
      let imagestack = new ImageStack();
      imagestack.init(imagestackEl)
    })
  }

  if(container.querySelector(".site-intro")) {
    let introEl = container.querySelector(".site-intro");
    let intro = new Intro();
    intro.init(introEl)
  }

  if(container.querySelector(".project-header")) {
    let projectHeaderEl = container.querySelector(".project-header");
    let projectHeader = new ProjectHeader();
    projectHeader.init(projectHeaderEl)
  }

  // if(container.querySelector(".intro--can")) {
  //   can.init(canEl)
  //   webGlDither.addLiquidPlane()
  // }
}

function finished(data) {
  data.next.container.classList.remove('onload')

  //autoplay videos after barba transition
  autoplayVideos(data.next.container)
}

// const dataOnLoad = {
//   next: {
//     container: initalContainer,
//     namespace: initalContainer.dataset.barbaNamespace
//   }
// }
// entering(dataOnLoad);

function autoplayVideos(container) {
  var vids = container.querySelectorAll("video[autoplay]");
  vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });
}
autoplayVideos(document)


document.body.classList.remove('is-loading')
setColors(initalContainer.dataset.barbaNamespace)


initBackToTop(document)
