import { spanFerkel } from './../../js/utils/math.js';
import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, touchDevice, siteurl, addGlobalEventListener} from './../../js/utils/events.js';

export default class Stage {
  init(root) {
    this.root = root;
    this.wrapper = this.root.querySelector('.stage__wrapper');
    this.reel = this.root.querySelector('.stage__card--reel');
    this.reelVideo = this.root.querySelector('.stage__card--reel video');

    this.reel.addEventListener('click', () => {
      this.toggleReel(this)
    });

    if(touchDevice === false && doc.w > 720) {
      this.root.addEventListener('mousemove', () => {
        this.mouseMove(this)
      });
      this.root.addEventListener('mouseleave', () => {
        this.mouseLeave(this)
      });
    }
  }
  toggleReel(comp) {
    if(comp.reel.classList.contains('active')) {
      this.reelVideo.muted = true;
    } else {
      this.reelVideo.muted = false;
    }
    this.reel.classList.toggle('active');
  }
  mouseMove(comp) {
    const mouseX = cursor.x;
    const mouseY = cursor.y;
    const leftX = mouseX * -2 + 1
    const topY = mouseY * -2 + 1
    const center = {
      x: leftX - doc.w / 2,
      y: topY - doc.h / 2
    }
    const distance = Math.sqrt(center.x**2 + center.y**2);

    comp.wrapper.style.zIndex = 10;
    comp.wrapper.style.transform = `
      translate3d(0 ,0 , 40px)
      rotate3d(
        ${topY * 0.5},
        ${-leftX * 0.5},
        0,
        ${Math.log(distance)* 0.75}deg
      )
    `;
  }
  mouseLeave(comp) {
    comp.wrapper.style.transform = '';
    comp.wrapper.style.zIndex = '';
  }
}

