import { doc } from './../../js/utils/events.js';

export default class Intro {
  init(root) {
    this.root = root;
    this.reelContainer = this.root.querySelector('.intro__reel');
    this.enterButton = this.root.querySelector('.intro__yes-button');
    this.render();
  }

  render() {
    if (doc.w >= 960) {

      //dither shader is not transparent in safari ios and needs to be skipped at all times
      //see the same conditional statement in the dither shader
      const isSafariiOS = /Safari/.test(navigator.userAgent) && /iPhone|iPad|iPod/.test(navigator.userAgent);
      if (isSafariiOS) {
          // This code will only run if the user is on Safari iOS
          window.location.href = this.enterButton.href
      } else {
        const videoSource = this.reelContainer.getAttribute('data-src');
        const videoType = this.reelContainer.getAttribute('data-type');

        const video = `<video muted loop playsinline autoplay width="100%">
          <source src="${videoSource}" type="${videoType}">
        </video>`;

        this.reelContainer.innerHTML = video;

        const videoElement = this.reelContainer.querySelector('video');
        videoElement.play();
      }

      //show logo, move ditherCanvas up to z index 100
      //hide logo on leave, move ditherCanvas back to -1
    } else {
      window.location.href = this.enterButton.href
    }
  }
}
