import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, touchDevice, stopLenis, startLenis, lenisScrollTo} from './../../js/utils/events.js';
import { lerp, map } from './../../js/utils/math.js';
import { gsap } from "gsap";

export default class Offcanvas {
  init(root) {
    this.root = root
    this.button = this.root.querySelector('.button--vimeo-consent')
    this.iframeDump = document.querySelector('.vimeo-consent__iframe-dump').dataset.iframe
    this.iframeTarget = document.querySelector('.vimeo__iframe-wrapper')
    this.button.addEventListener('click', () => {
      this.granted(this)
    });
    // console.log('vimeo consent init')
    if(this.getCookie('vimeoagreement') === true) {
      // console.log(this.getCookie('vimeoagreement'));
      // console.log('cookie already set')
      comp.iframeTarget.innerHTML = comp.iframeDump
      comp.root.style.display = 'none';
    }
  }
  granted(comp, ) {
    //set cookie
    // console.log('cookie granted just now')
    comp.iframeTarget.innerHTML = comp.iframeDump
    comp.root.style.display = 'none';
    comp.setCookie('vimeoagreement', true, 31);
    // this.button.removeEventListener('click', this.granted);
  }
  setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return false;
  }
}



