import * as THREE from 'three'
import { gsap } from "gsap";
import Experience from '../Experience.js'
import { doc, cursor, updatePositions, scrollTop, siteurl, touchDevice, registeredLoops, initBackBtn, initBackToTop, startLenis, lenisScrollTo} from '../../utils/events.js';
import { map, clamp} from '../../utils/math.js';

export default class Can
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
          this.debugFolder = this.debug.ui.addFolder('can')
        }

        // Resource
        this.resource = this.resources.items.canModel

        this.setModel()
    }

    setModel()
    {
        this.model = this.resource.scene
        // this.model.scale.set(50, 50, 50)
        this.model.position.set(0, 0, 0)
        this.model.visible = false;
        this.scene.add(this.model)

        if (this.debug.active) {
          this.debugFolder.add(this.model, 'visible').name('Visible');
        }

        this.model.traverse((child) =>
        {
          if (child instanceof THREE.Mesh) {
            child.castShadow = true;
          }
        })

        this.canTab = this.model.children.filter(child => child.name === 'Tab')[0];
        this.canLid = this.model.children.filter(child => child.name === 'Lid')[0];
        this.canBody = this.model.children.filter(child => child.name === 'Can')[0];
        this.canLiquidLevel = this.model.children.filter(child => child.name === 'liquidlevel')[0];

        if(document.querySelector('.container').dataset.barbaNamespace == "culture") {
          this.show()
        }
    }

    show() {
      this.canPlaceholder = document.querySelector('.can-placeholder')
      // this.canSize = this.canPlaceholder.offsetHeight / 13
      this.placeholderHeight = this.canPlaceholder.offsetHeight
      this.canSize = this.placeholderHeight / 9.906397 // 9.906397 height of box object returned by three js

      this.model.scale.set(this.canSize, this.canSize, this.canSize)
      this.model.visible = true;
      this.canPourArea = document.querySelector('.can-pour')
      this.canPourAreaTop = this.canPourArea.offsetTop
      this.offsetTop = this.canPourAreaTop + (this.sizes.width / 2) - (this.sizes.height / 2)
      // this.model.position.set(0, this.canPourAreaTop, 0)

      this.offsetAnimation = ((this.sizes.height - this.sizes.width) / 2) + (this.sizes.width / 5)//to start when liquid hits bottom

      this.animationStart = this.canPourAreaTop - this.sizes.height - this.offsetAnimation
      this.animationEnd = this.canPourAreaTop + this.sizes.width - this.offsetAnimation

      // this.animationStart = this.canPourAreaTop - this.sizes.height
      // this.animationEnd = this.canPourAreaTop + this.sizes.width

      this.animation()

    }

    hide() {
      this.model.visible = false;
    }

    animation() {
      this.currentTransforms = {
        positionX: 0, positionY: 0, positionZ: 0,
        rotationX: 0, rotationY: 0, rotationZ: 0,
        rotationTabLid: 0
      }

      let canPositions = {
        // offScreenBottom: {
        //   positionX: doc.w / 2, positionY: doc.h * 1.5,
        //   rotationX: 0, rotationY: - Math.PI, rotationZ:  - Math.PI * 0.2, // Math.PI * 0.05,
        //   rotationTabLid: 0
        // },
        offScreenLeft: {
          positionX: doc.w / 2, positionY: 0, positionZ: 0,
          rotationX: 0, rotationY: -3, rotationZ: 0, // Math.PI * 0.05,
          rotationTabLid: 0
        },
        initalPos: {
          positionX: 0, positionY:  -this.placeholderHeight, positionZ: 0,
          rotationX: -0.2, rotationY: 0.6, rotationZ: 0.2,
          rotationTabLid: 0
        },
        // prepareOpen: {
        //   positionX: 0, positionY:  0, positionZ: 0,
        //   rotationX: -0.2, rotationY: 1, rotationZ: -0.1,
        //   rotationTabLid: 0
        // },
        prepareOpen: {
          positionX: 0, positionY:  0, positionZ: 0,
          rotationX: -0.2, rotationY: 0.968, rotationZ: 0.2,
          rotationTabLid: 0
        },
        open: {
          positionX: 0, positionY: 0, positionZ: 0,
          rotationX: -0.212, rotationY: 0.968, rotationZ: 0.132,
          rotationTabLid: -0.25
        },
        pour: {
          positionX: (this.placeholderHeight * 0.35), positionY: (this.placeholderHeight * 0.3), positionZ: 0,
          rotationX: -0.116, rotationY: 0.968, rotationZ: -0.6,
          rotationTabLid: -0.25
        },
        center: {
          positionX: doc.w / 2, positionY: doc.h / 2, positionZ: 0,
          rotationX: 0, rotationY: 0, rotationZ: 0,
          rotationTabLid: 0
        }
      }

      // this.currentTransforms = canPositions.prepareOpen

      if (this.debug.active) {
        this.debugFolder.add(this.currentTransforms, 'rotationX', -2, 2).name('rotationX');
        this.debugFolder.add(this.currentTransforms, 'rotationY', -2, 2).name('rotationY');
        this.debugFolder.add(this.currentTransforms, 'rotationZ', -2, 2).name('rotationZ');
      }

      this.openCan = gsap.timeline({paused: true, ease: "bounce.out"}).fromTo(this.currentTransforms, canPositions.initalPos, canPositions.prepareOpen)
      this.openCan.duration(0.8)
      const openLid = gsap.to(this.currentTransforms, {...canPositions.open, ...{duration: 0.2, ease: "back.out(1.7)"}} )
      this.openCan.add(openLid)
      const startPour = gsap.to(this.currentTransforms, {...canPositions.pour, ...{delay: 0.1, duration: 0.3, }} )
      this.openCan.add(startPour)

      // this.openCan.play()
    }

    resize() {
      // console.log('reszie can')
      if(this.model.visible) {
        this.show()
      }
    }

    update() {
      if(this.model.visible) {
        // this.model.position.y = -this.offsetTop + scrollTop
        // this.model.position.y = 0

        let progress = clamp(map(scrollTop, this.animationStart, this.animationEnd, 0, 1))
        // console.log(progress)

        this.openCan.progress(clamp(map(progress, 0.25, 0.5, 0, 1)))

        this.model.position.set(
          this.currentTransforms.positionX,
          -this.offsetTop + scrollTop + this.currentTransforms.positionY,
          this.currentTransforms.positionZ,
        )
        this.model.rotation.set(
          this.currentTransforms.rotationX * Math.PI + (map(cursor.y, 0, 1, 0.03, -0.03)),
          this.currentTransforms.rotationY * Math.PI + (map(cursor.x, 0, 1, -0.02, 0.04)),
          this.currentTransforms.rotationZ * Math.PI,
        )

        this.canLid.rotation.set(0, 0, this.currentTransforms.rotationTabLid * Math.PI)
        this.canTab.rotation.set(0, 0, this.currentTransforms.rotationTabLid * Math.PI)
      }
    }
}
