import * as THREE from 'three';
import Experience from './Experience.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { DitherShader } from './Utils/shaders/dither-shader.js';

export default class DitherRenderer {
  constructor() {
    this.experience = new Experience();
    this.canvas = this.experience.canvas;
    this.sizes = this.experience.sizes;
    this.ditherCanvas = document.querySelector('canvas.webglDither');
    THREE.ColorManagement.enabled = false
    this.renderer = new THREE.WebGLRenderer({ canvas: this.ditherCanvas });
    this.ditherScene = this.experience.ditherScene;
    this.camera = this.experience.camera.instance;
    this.pixelSize = 4 * Math.min(this.sizes.pixelRatio, 2)

    this.setInstance();
    this.createPostProcessing();
  }

  setInstance() {
    // this.renderer.physicallyCorrectLights = true;
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    // this.renderer.shadowMap.enabled = true;
    // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setSize(this.sizes.width / this.pixelSize, this.sizes.height / this.pixelSize);
    this.renderer.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));

    // this.ditherCanvas.style.transform = `scale(${this.pixelSize})`;
  }

  createPostProcessing() {
    this.effectComposer = new EffectComposer(this.renderer);

    this.renderPass = new RenderPass(this.ditherScene, this.camera);
    this.effectComposer.addPass(this.renderPass);

    this.ditherPass = new ShaderPass(DitherShader);
    this.ditherPass.material.uniforms.uClamp.value = new THREE.Vector2(0.03, 0.241);

    //check browser to prevent strange alpha channel color offset
    /*const isSafariiOS = /Safari/.test(navigator.userAgent) && /iPhone|iPad|iPod/.test(navigator.userAgent);
    if (isSafariiOS && this.ditherPass) {
      // This code will only run if the user is on Safari iOS
      this.ditherPass.material.uniforms.uTransparency.value = 1.0;
      // console.log('remove transparancy in safari')
    } else {
      // console.log(this.ditherPass)
      this.ditherPass.material.uniforms.uTransparency.value = 0.0;
      // console.log('add dither transparancy')
    }*/

    //use css filter screen to make logo dither transparent instead. -> work around for strange color offset in some browsers
    //css style is added in barba.js handlers
    this.ditherPass.material.uniforms.uTransparency.value = 1.0;

    this.effectComposer.addPass(this.ditherPass);

    if (this.experience.debug.active) {
      this.debugFolder = this.experience.debug.ui.addFolder('ditherRenderer');
      this.debugFolder.add(this.ditherPass, 'enabled').name('ditherPass');

      // Create a subfolder for the uClamp value slider
      const uClampFolder = this.debugFolder.addFolder('Shader');
      uClampFolder.add(this.ditherPass.material.uniforms.uClamp.value, 'x', 0, 1).name('uClamp X');
      uClampFolder.add(this.ditherPass.material.uniforms.uClamp.value, 'y', 0, 1).name('uClamp Y');
      uClampFolder.add(this.ditherPass.material.uniforms.uTransparency, 'value', 0, 1).name('uTransparency (disable in Safari)');
    }
  }

  setColors(background, front) {
    var colorObj = new THREE.Color( front );
    var colorBGObj = new THREE.Color( background );
    this.ditherPass.material.uniforms.uPixelColor.value = {x: (colorObj.r), y: (colorObj.g), z: (colorObj.b)}
    this.ditherPass.material.uniforms.uBGColor.value = {x: (colorBGObj.r), y: (colorBGObj.g), z: (colorBGObj.b)}
  }


  resize() {
    this.renderer.setSize(this.sizes.width / this.pixelSize, this.sizes.height / this.pixelSize);
    this.renderer.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    this.effectComposer.setSize(this.sizes.width / this.pixelSize, this.sizes.height / this.pixelSize);
  }

  update() {
    this.renderer.autoClear = false;
    this.renderer.clear();

    this.effectComposer.render();

    this.renderer.autoClear = true;
  }
}
